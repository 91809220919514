import { Box, Header, Layer, ResponsiveContext } from "grommet";
import { FormSearch } from "grommet-icons";
import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { fetchApiClient } from "@Api/auth";
import { fetchCareUnits, fetchContracts, fetchServices } from "@Api/filter";

import Button from "@Components/Common/Button";
import MobilePageHeader from "@Components/Common/MobilePageHeader";
import Confirmation from "@Components/Confirmation";
import EditBookingHeader from "@Components/EditBookingHeader";
import PatientForm from "@Components/PatientForm";
import Result from "@Components/Result";
import Search from "@Components/Search";

import {
  setApiClient,
  setCareUnits,
  setCareUnitsFilter,
  setContractIds,
  setPage,
  setServiceFilter,
  setServices,
} from "@Hooks/useBooking/actions";
import {BookingPage, SelectOption, SelectServiceOption} from "@Hooks/useBooking/types";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import useBookingState from "@Hooks/useBooking/useBookingState";
import { pushErrorAction } from "@Hooks/useError/actions";
import useErrorAction from "@Hooks/useError/useErrorAction";

import theme from "@Style/theme";

import messages from "./messages";
import { CareUnit, Service } from "@Api/bookingTypes";

interface Props {}

const BookPage: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const { page, booking, clientId, apiClient, reservation } = useBookingState();
  const bookingDispatch = useBookingAction();
  const errorDispatch = useErrorAction();
  const [loadedCareUnits, setLoadedCareUnits] = useState(false);
  const [loadedServices, setLoadedServices] = useState(false);
  const [loadedApiClient, setLoadedApiClient] = useState(false);
  const [drawLoading, setDrawLoading] = useState(false);

  const showSearch = () => {
    bookingDispatch(setPage(BookingPage.SEARCH_SLOTS));
  };

  const renderSearchPage = () => {
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box direction="row" flex overflow={{ horizontal: "hidden" }}>
            {size === "xsmall" || size === "small"
              ? renderCollapsingSearch()
              : renderFixedSearch()}
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  const renderFixedSearch = () => {
    return (
      <Box fill direction={"row"} overflow={"hidden"}>
        <Box
          height={{ min: "100vh" }}
          width={{ min: "450px" }}
          background={theme.custom.menu.background}
          color={theme.custom.menu.text}
          elevation="small"
          align="center"
          overflow={"auto"}>
          <Search setDrawLoading={setDrawLoading} />
        </Box>
        <Box direction="column" fill={"horizontal"}>
          {booking && (
            <Header>
              <EditBookingHeader />
            </Header>
          )}
          <Result drawLoading={drawLoading} />
        </Box>
      </Box>
    );
  };

  const renderCollapsingSearch = () => {
    return (
      <Fragment>
        {page === BookingPage.SELECT_SLOT && (
          <Layer>
            <MobilePageHeader
              label={formatMessage(messages.header)}
              button={
                <Button
                  width="30px"
                  margin={{ right: "5px" }}
                  plain
                  onClick={showSearch}>
                  <FormSearch />
                </Button>
              }>
              {booking && <EditBookingHeader />}
            </MobilePageHeader>
            <Box flex align="center" justify="center">
              <Result drawLoading={drawLoading} />
            </Box>
          </Layer>
        )}
        <Box
          height={{ min: "100%" }}
          fill
          background={theme.custom.menu.background}
          color={theme.custom.menu.text}
          align="center"
          overflow={"auto"}>
          <Search setDrawLoading={setDrawLoading} />
        </Box>
      </Fragment>
    );
  };

  const renderPatientDetailsPage = () => {
    return <PatientForm />;
  };

  const renderBookPage = () => {
    return <Confirmation />;
  };

  const renderPage = (p: BookingPage) => {
    switch (p as BookingPage) {
      case BookingPage.SEARCH_SLOTS:
      case BookingPage.SELECT_SLOT:
        return renderSearchPage();
      case BookingPage.PATIENT_DETAILS:
        return renderPatientDetailsPage();
      case BookingPage.BOOK:
        return renderBookPage();
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (apiClient) {
      setLoadedApiClient(true);
    } else {
      fetchApiClient()
        .then((result) => {
          if (!isCancelled) {
            bookingDispatch(setApiClient(result));
            setLoadedApiClient(true);
          }
        })
        .catch((reason) => {
          errorDispatch(
            pushErrorAction({
              title: formatMessage(messages.loadingErrorTitle),
              message: formatMessage(messages.loadingErrorMessage),
            })
          );
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch, errorDispatch, apiClient, formatMessage]);

  const fetchData = async (isCancelled: boolean) => {
    let contractIds;
    if (reservation) {
      contractIds = await fetchContracts(clientId);
      bookingDispatch(setContractIds(contractIds));
    }

    if (!isCancelled) {

      const [careUnits, services] = await Promise.all([
        fetchCareUnits(reservation, contractIds),
        fetchServices(clientId, reservation, contractIds)
      ]);

      handleDispatch(careUnits, services, !isCancelled);
    }
  };

  const handleDispatch = (careUnits: CareUnit[], services: Service[], isNotCancelled: boolean) =>{
    if(isNotCancelled){
      bookingDispatch(setCareUnits(careUnits));
      bookingDispatch(setServices(services));

      // Pre-select careUnit if only one option present
      if (careUnits.length === 1) {
        bookingDispatch(setCareUnitsFilter(careUnits.map(c => ({ value: c.id, label: c.name, selected: true } as SelectOption))))
      }

      // Pre-select service if only one option present
      if (services.length === 1) {
        const service = services[0];
        bookingDispatch(setServiceFilter({
            ...service,
            value: service.id + service.subContractId,
            label: service.name,
            duration: service.realTimeSpan,
            selected: true
          } as SelectServiceOption)
        )
      }

      setLoadedCareUnits(true);
      setLoadedServices(true);
    } else{
      errorDispatch(
          pushErrorAction({
            title: formatMessage(messages.loadingErrorTitle),
            message: formatMessage(messages.loadingErrorMessage),
          })
      );
    }
  }

  useEffect(() => {
    let isCancelled = false;

    fetchData(isCancelled);

    return () => {
      isCancelled = true;
    };
  }, []);
  
  if (!(loadedCareUnits && loadedServices && loadedApiClient)) {
    return null;
  }

  return renderPage(page);
};

export default BookPage;
