import { Box, Heading, Image, ResponsiveContext, ThemeContext } from "grommet";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import styled from "styled-components";

import useBookingState from "@Hooks/useBooking/useBookingState";
import { pushErrorAction } from "@Hooks/useError/actions";
import useErrorAction from "@Hooks/useError/useErrorAction";

import BankIdIcon from "@Resources/img/BankID.png";
import IconLogin from "@Resources/img/icon-login.png";

import theme from "@Style/theme";

import Button from "../Common/Button";
import Spinner from "../Common/Spinner";
import Version from "./Version";
import messages from "./messages";
import { handleFederatedLogin } from "@Api/auth";

interface Props {}

const LoginPage = styled(Box)`
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const LoginWrapper = styled(Box)`
  width: 438px;
  height: 100%;

  & h1 {
    color: ${({ theme }) => theme.global.colors.headerText};
  }
`;

const LoginBox = styled(Box)`
  background: ${({ theme }) => theme.global.colors.backgroundLight};
  border: 1px solid ${({ theme }) => theme.global.colors.infoBoxBorder};
  border-radius: 9px;
  margin: 5px 0;
  width: 100%;
  min-height: 400px;
  max-height: 500px;
  flex: 1;
  flex-direction: column;
`;

const LoginBankID: React.FC<Props> = () => {
  const { bookingId, clientId, reservation } = useBookingState();
  const { formatMessage } = useIntl();
  const errorDispatch = useErrorAction();
  const [pending, setPending] = useState(false);

  if (!(bookingId || clientId)) {
    errorDispatch(
      pushErrorAction({
        title: formatMessage(messages.noCustomerNoTitle),
        message: formatMessage(messages.noCustomerNoMessage),
        fixed: true,
      })
    );
  }

  const handleBankIdLogin = async () => {
    setPending(true);

    try {
      const loginSession = await handleFederatedLogin(clientId, bookingId, reservation);
      window.location.href = loginSession.redirectUrl;
    } catch (error) {
      errorDispatch(
        pushErrorAction({
          title: formatMessage(messages.loginFailed),
          message: formatMessage(messages.loginFailed),
          fixed: true,
        })
      );
    }
  };

  const size = React.useContext(ResponsiveContext);
  const bankidMargin =
    "xsmall" === size ? "13px" : "small" === size ? "18px" : "28px";
  return (
    <ThemeContext.Extend
      value={{
        global: { colors: { placeholder: theme.global.colors.brand } },
      }}>
      <LoginPage flex>
        <LoginWrapper>
          <Box
            style={{ flex: 1, maxHeight: "120px", minHeight: "55px" }}
            direction={"row"}>
            {("xlarge" === size || "large" === size || "medium" === size) && (
              <Image
                src="api/v1/custom/logo.png"
                height={40}
                width={120}
                style={{ position: "absolute", top: "20px", right: "35px" }}
              />
            )}
            <Heading alignSelf={"end"} level="1" margin={{ vertical: "small" }}>
              {formatMessage(messages.header)}
            </Heading>
          </Box>
          <LoginBox
            alignSelf={"center"}
            pad={{ horizontal: "medium" }}
            justify="center"
            align={"center"}
            style={{ position: "relative" }}>
            <Image
              src={BankIdIcon}
              height={30}
              width={32}
              style={{
                position: "absolute",
                bottom: bankidMargin,
                right: bankidMargin,
              }}
            />
            <Image src={IconLogin} />
            {pending ? (
              <Box pad={"medium"} gap={"medium"}>
                <Spinner color={theme.global.colors.text} size={35} />
                <Heading level={2} alignSelf={"center"} textAlign={"center"}>
                  {formatMessage(messages.waitingForBankId)}
                </Heading>
              </Box>
            ) : (
              <Box pad={{ horizontal: "medium" }} align={"center"}>
                <Button
                  width={"200px"}
                  height={"36px"}
                  margin={"20px"}
                  primary
                  label={formatMessage(messages.buttonForBankId)}
                  type="submit"
                  onClick={handleBankIdLogin}
                />
              </Box>
            )}
          </LoginBox>
        </LoginWrapper>
        <Version />
      </LoginPage>
    </ThemeContext.Extend>
  );
};

export default LoginBankID;
