import { Box, Heading, Image, Text } from "grommet";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { CareUnit, Resource } from "@Api/bookingTypes";

import IconCameraDisabled from "@Resources/img/icon-camera-gray.png";
import IconCamera from "@Resources/img/icon-camera-happyblue.png";
import IconInfo from "@Resources/img/icon-info-happyblue.png";

import theme from "@Style/theme";

import Button from "./Button";
import messages from "./messages";

interface Props {
  small: boolean;
  video?: boolean;
  disabled?: boolean;
  serviceName: string;
  careUnit: CareUnit;
  resource: Resource;
  startTime: string;
  button?: ButtonProps;
  callText?: string;
}

interface ButtonProps {
  label: string;
  primary?: boolean;
  secondary?: boolean;
  onSelect: () => void;
}

const SlotComponent: React.FC<Props> = ({
  small,
  video,
  disabled = false,
  serviceName,
  careUnit,
  resource,
  startTime,
  button,
  callText,
}) => {
  const { formatMessage } = useIntl();
  const boxHeight = (small ? 90 : 65) + "px";
  const boxWidth = (small ? 360 : button ? 655 : 600) + "px";

  const iconWidthNum = 19;
  const iconWidth = iconWidthNum + 10 + "px";
  const leftWidthNum = small ? 210 : button ? 505 : 440;
  const leftWidth = leftWidthNum + "px";
  const leftColumnWidthNum = (small ? leftWidthNum : leftWidthNum / 2) - 20;
  const leftColumnWidth = leftColumnWidthNum + "px";
  const leftHeadingWidth = leftColumnWidthNum - iconWidthNum + "px";

  const rightWidthNum = 150;
  const rightWidth = rightWidthNum + "px";
  const timeWidth = 120 + "px";
  const buttonWidth = 120 + "px";

  let address = resource.careUnit.streetAddress;
  if (resource.careUnit.city && resource.careUnit.city !== "") {
    address += ", " + resource.careUnit.city;
  }

  return (
    <Box
      height={{ min: boxHeight }}
      width={{ max: boxWidth, min: boxWidth }}
      flex={false}
      direction="row"
      margin={{ vertical: "7px" }}
      border={{ color: theme.global.colors.infoBoxBorder, size: "1px" }}
      round="2px">
      <Box
        direction={"column"}
        wrap={false}
        pad={"10px"}
        width={{ max: leftWidth, min: leftWidth }}
        background={theme.global.colors.infoBoxBackground}>
        <Box direction={small ? "column" : "row"}>
          <Box direction="column" wrap={true}>
            <Box direction={"row"}>
              <Box width={{ max: leftHeadingWidth, min: leftHeadingWidth }}>
                <WrapHeading
                  level={3}
                  disabled={disabled}
                  color={theme.global.colors.brand}>
                  {serviceName}
                </WrapHeading>
              </Box>
              <Box
                pad={{ right: "10px" }}
                width={{ max: iconWidth, min: iconWidth }}>
                {video && (
                  <Image
                    src={disabled ? IconCameraDisabled : IconCamera}
                    height={12}
                    width={19}
                    a11yTitle={formatMessage(messages.videoConsultation)}
                    title={formatMessage(messages.videoConsultation)}
                  />
                )}
              </Box>
            </Box>
            <Box width={{ max: leftColumnWidth, min: leftColumnWidth }}>
              <WrapText disabled={disabled}>
                {resource.name + ", " + resource.occupation.name}
              </WrapText>
            </Box>
          </Box>
          <Box direction="column" wrap={true}>
            <Box direction={"row"}>
              <Box
                pad={{ left: !small ? "10px" : "0px" }}
                width={{ max: leftHeadingWidth, min: leftHeadingWidth }}>
                <WrapHeading level={4} disabled={disabled}>
                  {resource.careUnit.name}
                </WrapHeading>
              </Box>
            </Box>
            <Box
              justify={"center"}
              pad={{ left: !small ? "10px" : "0px" }}
              width={{ max: leftColumnWidth, min: leftColumnWidth }}>
              <WrapText disabled={disabled}>{address}</WrapText>
            </Box>
          </Box>
        </Box>
        {callText && (
          <Box direction={"row"} a11yTitle={callText} title={callText}>
            <Image
              alignSelf={"end"}
              margin={{ right: "5px" }}
              src={IconInfo}
              height={15}
              width={15}
            />
            <div
              style={{
                maxHeight: "22px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}>
              <Text size={"xsmall"}>{callText}</Text>
            </div>
          </Box>
        )}
      </Box>
      <Box
        pad={"14px"}
        direction="column"
        wrap={true}
        align={"center"}
        alignSelf={"center"}
        width={{ max: rightWidth, min: rightWidth }}>
        <Box
          align={"center"}
          justify={"center"}
          width={{ min: timeWidth, max: timeWidth }}
          margin={{ bottom: "5px" }}>
          <NoWrapHeading level={4} disabled={disabled}>
            {moment(startTime).format("ddd DD MMM HH:mm")}
          </NoWrapHeading>
        </Box>
        {button && (
          <Box align={"center"} justify={"center"}>
            <Button
              width={buttonWidth}
              fill={"horizontal"}
              primary={button.primary}
              secondary={button.secondary}
              onClick={button.onSelect}
              type="button"
              label={button.label}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface TextProps {
  disabled: boolean;
}

const NoWrapHeading = styled(Heading)<TextProps>`
  & {
    color: ${({ disabled }) => disabled && theme.global.colors.textDisabled};
    text-transform: capitalize;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
`;

const WrapHeading = styled(Heading)<TextProps>`
  & {
    color: ${({ disabled }) => disabled && theme.global.colors.textDisabled};
    margin: 0px;
    position: relative;
    word-wrap: break-word;
  }
`;

const WrapText = styled(Text)<TextProps>`
  & {
    color: ${({ disabled }) => disabled && theme.global.colors.textDisabled};
    text-transform: capitalize;
    margin: 0px;
  }
`;

export default SlotComponent;
