import { useContext } from "react";

import { ErrorActionContext } from "@Context/ErrorProvider";

const useErrorAction = () => {
  const context = useContext(ErrorActionContext);
  if (context === undefined) {
    throw new Error("useErrorAction must be used within a ErrorProvider");
  }
  return context;
};

export default useErrorAction;
