import { defineMessages } from "react-intl";

export default defineMessages({
  header: "Tidbokning",
  patientDetails: "Uppgifter",
  personalIdentificationNumber: "Personnummer",
  inputPatientDetails: "Ange uppgifter som bokningen avser",
  mandatoryFields: "* Obligatoriska fält",
  checkboxConsent:
    "Jag samtycker till att en bokningsbekräftelse med av- och ombokningslänk skickas till angiven e-post *",
  checkboxSms: "Jag vill ha påminnelse via SMS innan besöket",
  informationCancelBefore:
    "Senast tillåtna tidpunkt för av- och ombokning innan besöket: {hours} timmar innan.",
  informationCancelEmail:
    "Kontakta mottagningen för att boka/omboka tid om du ej samtycker till utskick av e-postmeddelande.",
  buttonBack: "Tillbaka",
  buttonBook: "Boka",
  bookingFailedTitle: "Bokningen misslyckades",
  bookingFailedMessage: "Bokningen misslyckades",
  videoConfigurationError:
    "Det är ej möjligt att boka videokonsultation för vald personal. Vänligen välj annan personal.",
  invalidFirstName: "Ange förnamn",
  invalidLastName: "Ange efternamn",
  invalidEmail: "Ange giltig e-post",
  invalidEmailCheck: "E-post matchar ej",
  invalidPhone:
    "Ange telefonnummer (endast siffror, ett -, mellanslag eller landskod)",
  invalidCellphone:
    "Ange mobilnummer (endast siffror, ett -, mellanslag eller landskod)",
  invalidCustomField: "Fältet är obligatoriskt",
  fieldValueTooLong: "Värdet är för långt",
  bookingOccupied:
    "Tiden är redan tagen, försök på nytt genom att välja en annan tid",
  videoWouldResultInCompanyBookingWithNoPatient:
    "Det är ej möjligt att boka videokonsultation, var vänlig kontakta din mottagning.",
  consentMandatory: "Samtycke till bokningsbekräftelse är obligatoriskt",
  invalidFields: "Följande fält saknas eller är felaktigt ifyllda:",
  consentMissing: "Samtycke saknas"
});
