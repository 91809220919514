import { Box, FormField } from "grommet";
import React from "react";
import styled from "styled-components";

const StyledFormField = styled(FormField)`
  & > div {
    border-color: ${({ theme }) => theme.custom.patientDetails.formFieldBorder};
  }
  & > label {
    color: ${({ theme }) => theme.custom.patientDetails.label};
  }
  & input {
    font-weight: normal;
    color: ${({ theme }) => theme.custom.patientDetails.input};
  }
`;

interface Props {
  width: string;
  label: string;
  name: string;
  defaultValue: string;
  invalid?: boolean;
  errorMessage?: string;
}
const PatientFormField: React.FC<Props> = ({
  width,
  label,
  name,
  defaultValue,
  invalid,
  errorMessage,
}) => {
  return (
    <Box width={width} pad={{ horizontal: "25px" }}>
      <StyledFormField
        label={label}
        name={name}
        defaultValue={defaultValue}
        type="text"
        error={invalid && errorMessage}
      />
    </Box>
  );
};

export default PatientFormField;
