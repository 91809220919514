import { ErrorType } from "@Api/errorTypes";

import messages from "./messages";

export const getLoginErrorMessage = (error: string, formatMessage: any) => {
  switch (error) {
    case ErrorType.PATIENT_AND_BOOKING_PATIENT_MISMATCH:
      return formatMessage(messages.patientMismatch);
    case ErrorType.BOOKING_NOT_FOUND:
      return formatMessage(messages.bookingMissing);
    case ErrorType.NO_PATIENT_ON_BOOKING:
      return formatMessage(messages.noPatientOnBooking);
    default:
      return formatMessage(messages.loginFailed);
  }
};
