import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";

const colors = {
  nightBlue: "#03316B",
  darkBlue: "#112640",
  happyBlue: "#357ED9",
  borderBlue: "#164C91",
  white: "#FFFFFF",
  humbleGrey: "#D9D9D9",
  lightGrey: "#F7F7F7",
  hazyGrey: "#7E838D",
  red: "#D96B6B",
  lightRed: "#FFF3F3",
  shadow: "#6E84A4",
};

const theme = {
  global: {
    drop: {
      background: {
        dark: colors.white,
        light: colors.white,
      },
    },
    font: {
      family: "Roboto",
    },
    breakpoints: {
      xsmall: {
        value: 670,
      },
      small: {
        value: 830,
      },
      medium: {
        value: 1120,
      },
      large: {
        value: 1250,
      },
      xlarge: {
        value: 1400,
      },
    },
    colors: {
      text: colors.nightBlue,
      textDisabled: colors.hazyGrey,
      brand: colors.happyBlue,
      "accent-1": colors.happyBlue,
      backgroundLight: colors.white,
      backgroundShade: colors.lightGrey,
      infoBoxBorder: colors.humbleGrey,
      infoBoxBackground: colors.lightGrey,
      formFieldBorder: colors.borderBlue,
      placeholder: colors.hazyGrey,
      focus: colors.happyBlue,
      shadow: colors.shadow,
      alertingBorder: colors.red,
    },
  },
  button: {
    border: {
      width: "1px",
      radius: "6px",
    },
  },
  calendar: {
    medium: {
      daySize: "46px",
    },
  },
  checkBox: {
    border: {
      color: {
        dark: colors.nightBlue,
        light: colors.nightBlue,
      },
    },
    hover: {
      border: {
        color: {
          dark: colors.nightBlue,
          light: colors.nightBlue,
        },
      },
    },
    toggle: {
      color: {
        dark: colors.nightBlue,
        light: colors.nightBlue,
      },
    },
    color: {
      dark: colors.nightBlue,
      light: colors.nightBlue,
    },
  },
  custom: {
    header: {
      text: colors.nightBlue,
      border: colors.humbleGrey,
    },
    checkBox: {
      color: colors.nightBlue,
    },
    button: {
      primary: {
        background: colors.happyBlue,
        text: colors.white,
        border: colors.happyBlue,
      },
      secondary: {
        background: colors.white,
        text: colors.nightBlue,
        border: colors.humbleGrey,
      },
    },
    patientDetails: {
      input: colors.happyBlue,
      label: colors.darkBlue,
      formFieldBorder: colors.borderBlue,
    },
    menu: {
      background: colors.nightBlue,
      text: colors.white,
      placeholder: colors.lightGrey,
    },
    error: {
      background: colors.lightRed,
      border: colors.red,
      text: colors.red,
    },
  },
  text: {
    medium: {
      size: "14px",
      height: "22px",
    },
  },
  heading: {
    weight: "bold",
    font: {
      family: "Montserrat",
    },
    level: {
      1: {
        medium: {
          size: "40px",
          height: "41px",
        },
      },
      2: {
        medium: {
          size: "24px",
          height: "31px",
        },
      },
      3: {
        medium: {
          size: "16px",
          height: "23px",
        },
      },
      4: {
        medium: {
          size: "12px",
          height: "19px",
        },
      },
    },
  },
};

export default deepMerge(grommet, theme);
