import { Box, ResponsiveContext, Text } from "grommet";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import { editBooking } from "@Api/booking";
import { ErrorType } from "@Api/errorTypes";

import { clearBooking } from "@Hooks/useBooking/actions";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import useBookingState from "@Hooks/useBooking/useBookingState";
import { pushErrorAction } from "@Hooks/useError/actions";
import useErrorAction from "@Hooks/useError/useErrorAction";

import Button from "../Common/Button";
import Modal from "../Common/Modal";
import SlotComponent from "../Common/SlotComponent";
import messages from "./messages";

interface Props {
  dismiss: () => void;
}

const EditBookingModal: React.FC<Props> = ({ dismiss }) => {
  const bookingDispatch = useBookingAction();
  const {
    booking,
    selectedSlot,
    apiClient,
    companySettings,
  } = useBookingState();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const errorDispatch = useErrorAction();
  const size = React.useContext(ResponsiveContext);
  const small = "xsmall" === size || "medium" === size;
  const modalWidth = small ? "412px" : "652px";

  if (!booking || !selectedSlot || !apiClient) {
    return null;
  }

  const onSubmit = () => {
    if (!(booking && booking.id)) {
      return;
    }

    const data = {
      booking: booking,
      newSlot: selectedSlot,
    };
    editBooking(booking.id, data)
      .then((newId) => {
        // When returning from edit booking, we need to force the confirmation
        // page to reload the booking since it has changed by the edit.
        // It is triggered by clearing the booking state.
        bookingDispatch(clearBooking());
        navigate("/book/" + newId + (location.search || ""));
      })
      .catch((reason) => {
        let message;

        let error = reason?.response?.data["message"];
        switch (error) {
          case ErrorType.BOOKING_NOT_FOUND:
            message = messages.bookingMissing;
            break;
          case ErrorType.VIDEO_CONFIGURATION_ERROR:
            message = messages.videoConfigurationError;
            break;
          case ErrorType.BOOKING_OCCUPIED:
            message = messages.bookingOccupied;
            break;
          default:
            message = messages.editFailedMessage;
        }
        errorDispatch(
          pushErrorAction({
            title: formatMessage(messages.editFailedTitle),
            message: formatMessage(message),
          })
        );
      });
  };

  const minHours =
    companySettings?.bookingCancelHoursBefore !== undefined
      ? companySettings.bookingCancelHoursBefore
      : apiClient.bookingCancelHoursBefore;
  const canBeCancelled = moment()
    .add(minHours, "hours")
    .isBefore(moment(booking.startTime));

  return (
    <Modal
      title={formatMessage(messages.title)}
      dismiss={dismiss}
      width={modalWidth}>
      <Box pad={{ vertical: "20px" }}>
        <SlotComponent
          small={small}
          disabled={true}
          video={booking.videoConsultation}
          serviceName={booking.mainService.name}
          careUnit={booking.resource.careUnit}
          resource={booking.resource}
          startTime={booking.startTime}
        />
      </Box>
      <Text>{formatMessage(messages.changeTo)}</Text>
      <Box pad={{ vertical: "20px" }}>
        <SlotComponent
          small={small}
          video={selectedSlot.serviceType?.videoConsultation}
          serviceName={selectedSlot.serviceName}
          careUnit={selectedSlot.resource.careUnit}
          resource={selectedSlot.resource}
          startTime={selectedSlot.startTime}
        />
      </Box>
      <Text>{formatMessage(messages.information)}</Text>
      <Box justify={"center"} direction={"row"}>
        <Button
          margin={"25px"}
          width={"140px"}
          fill={false}
          secondary
          label={formatMessage(messages.cancelButton)}
          onClick={dismiss}
        />
        <Button
          margin={"25px"}
          width={"140px"}
          fill={false}
          primary
          label={formatMessage(messages.submitButton)}
          onClick={() => {
            if (canBeCancelled) {
              onSubmit();
            } else {
              errorDispatch(
                pushErrorAction({
                  title: formatMessage(messages.errorEditNotAllowedTitle),
                  message: formatMessage(messages.errorEditNotAllowedMessage, {
                    hours: minHours,
                  }),
                })
              );
            }
          }}
        />
      </Box>
    </Modal>
  );
};

export default EditBookingModal;
