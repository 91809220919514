import { Calendar, DropButton, Image } from "grommet";
import React, { useState } from "react";
import styled from "styled-components";

import CalendarIcon from "@Resources/img/icon-calendar-white.png";

interface Props {
  small: boolean;
  value: string;
  locale: string;
  bounds: string[];
  placeholder: string;
  onChange: (value: string | undefined) => void;
}

const StyledDropButton = styled(DropButton)`
  & > div {
    justify-content: space-between;
  }
  & {
    color: ${({ theme }) => theme.custom.menu.text};
    border: none;
    box-shadow: none;
    text-align: start;
    padding: 11px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  }
  & :focus,
  :hover,
  :active,
  :focus-within {
    border: none;
    box-shadow: none;
  }
`;

const SearchDateInput: React.FC<Props> = ({
  small,
  value,
  locale,
  bounds,
  placeholder,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <StyledDropButton
      label={value}
      reverse={true}
      icon={<Image width={"16px"} height={"15px"} src={CalendarIcon} />}
      dropAlign={
        small
          ? { bottom: "bottom", right: "right" }
          : { top: "top", right: "right" }
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      dropProps={{ overflow: "hidden" }}
      dropContent={
        <Calendar
          firstDayOfWeek={1}
          alignSelf={"center"}
          style={{ textTransform: "capitalize", overflow: "hidden" }}
          size={"medium"}
          locale={locale}
          bounds={bounds}
          date={value}
          onSelect={(select: any) => {
            setOpen(false);
            onChange(select);
          }}
        />
      }
    />
  );
};

export default SearchDateInput;
