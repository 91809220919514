import { Dispatch, useReducer } from "react";

import { ErrorAction, ErrorState, ErrorTypeKeys } from "./types";

const initialState: ErrorState = {
  errors: [],
};

const errorReducer = (state: ErrorState, action: ErrorAction) => {
  switch (action.type) {
    case ErrorTypeKeys.PUSH_ERROR:
      return { ...state, errors: state.errors.concat(action.error) };
    case ErrorTypeKeys.POP_ERROR:
      return { ...state, errors: state.errors.slice(0, -1) };
    default:
      break;
  }
  return state;
};
export const useError = (): [ErrorState, Dispatch<ErrorAction>] => {
  const [state, dispatch] = useReducer(errorReducer, initialState);
  return [state, dispatch];
};
