import { useContext } from "react";

import { BookingStateContext } from "@Context/BookingProvider";

const useBookingState = () => {
  const context = useContext(BookingStateContext);
  if (context === undefined) {
    throw new Error("useBookingState must be used within a BookingProvider");
  }
  return context;
};

export default useBookingState;
