import axios from "axios";
import moment from "moment";
import qs from "qs";

import { SearchFilter } from "@Hooks/useBooking/types";

import {
  Booking,
  EditBooking,
  GroupedSlots,
  PostBooking,
  Slot,
  Slots,
} from "./bookingTypes";

export const fetchAvailableSlots = async (
  filter: SearchFilter,
  reservation: string | undefined
): Promise<GroupedSlots> => {
  const data = {
    startDate: filter.startDate?.format("yyyy-MM-DD"),
    earliestStartAt: filter.earliestStartAt
      ? filter.earliestStartAt.label
      : undefined,
    endDate: filter.endDate?.format("yyyy-MM-DD"),
    service: filter.service ? filter.service.id : undefined,
    resources:
      filter.resources.length > 0
        ? filter.resources.map((it) => it.value)
        : undefined,
    careUnits:
      filter.careUnits.length > 0
        ? filter.careUnits.map((it) => it.value)
        : undefined,
    minimumPeriod: filter.service ? filter.service.duration : undefined,
    reservation,
  };

  const results = await axios.get<Slots>("/api/v1/availability", {
    params: data,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "comma" });
    },
    validateStatus: function (status) {
      return status === 200;
    },
  });
  const grouped = results.data.slots.reduce(
    (result: Record<string, Slot[]>, slot: Slot) => {
      const key = moment(slot.startTime).startOf("day").toISOString();
      slot.serviceName = filter.service?.label || "";
      if (!result[key]) result[key] = [];
      result[key].push(slot);
      return result;
    },
    {}
  );

  return {
    slots: grouped,
    hasMoreHits: results.data.hasMoreHits,
  };
};

export const fetchBooking = async (id: string): Promise<Booking> => {
  const results = await axios.get(`/api/v1/booking/${id}`, {
    validateStatus: function (status) {
      return status === 200;
    },
  });
  return { ...results.data, id: id };
};

export const createBooking = async (data: PostBooking): Promise<Booking> => {
  const response = await axios.post(`/api/v1/booking`, data, {
    validateStatus: function (status) {
      return status === 201;
    },
  });
  return response.data;
};

export const cancelBooking = async (id: string): Promise<boolean> => {
  return await axios.delete(`/api/v1/booking/${id}`, {
    validateStatus: function (status) {
      return status === 204;
    },
  });
};

export const editBooking = async (
  id: string,
  data: EditBooking
): Promise<string> => {
  const response = await axios.put(`/api/v1/booking/${id}`, data, {
    validateStatus: function (status) {
      return status === 200;
    },
  });
  return response.data.id;
};
