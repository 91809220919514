import moment from "moment";
import { Dispatch, useReducer } from "react";

import {
  BookingAction,
  BookingPage,
  BookingState,
  BookingTypeKeys,
} from "./types";

const initialState: BookingState = {
  clientId: undefined,
  apiClient: undefined,
  careUnits: [],
  services: [],
  portalSettings: {
    defaultDateRange: 3,
    earliestStartAtHours: [],
  },
  filter: {
    startDate: undefined,
    endDate: undefined,
    service: undefined,
    resources: [],
    careUnits: [],
    earliestStartAt: undefined,
  },
  availableSlots: undefined,
  hasMoreHits: false,
  selectedSlot: undefined,
  selectedService: undefined,
  patientDetails: undefined,
  extraFieldResults: [],
  page: BookingPage.SEARCH_SLOTS,
  booking: undefined,
  bookingId: undefined,
  companySettings: undefined,
};

const bookingReducer = (state: BookingState, action: BookingAction) => {
  switch (action.type) {
    case BookingTypeKeys.SET_API_CLIENT:
      return { ...state, apiClient: action.apiClient };
    case BookingTypeKeys.SET_CLIENT_ID:
      return { ...state, clientId: action.clientId };
    case BookingTypeKeys.SET_BOOKING_ID:
      return { ...state, bookingId: action.bookingId };
    case BookingTypeKeys.SET_CARE_UNITS:
      return { ...state, careUnits: action.careUnits };
    case BookingTypeKeys.SET_SERVICES:
      return { ...state, services: action.services };
    case BookingTypeKeys.SET_SERVICE_TYPES:
      return { ...state, serviceTypes: action.serviceTypes };
    case BookingTypeKeys.SET_PORTAL_SETTINGS:
      const filter = state.filter;
      if (!filter.startDate) {
        filter.startDate = moment();
      }
      if (!filter.endDate) {
        filter.endDate = moment(filter.startDate).add(
          (action.portalSettings.defaultDateRange || 5) - 1,
          "day"
        );
      }
      return { ...state, portalSettings: action.portalSettings, filter };
    case BookingTypeKeys.SET_PATIENT:
      return { ...state, patientDetails: action.patient };
    case BookingTypeKeys.CLEAR_PATIENT:
      return { ...state, patientDetails: undefined };
    case BookingTypeKeys.SET_PAGE:
      return { ...state, page: action.page };
    case BookingTypeKeys.SET_SEARCH_FILTER:
      return { ...state, filter: action.filter };
    case BookingTypeKeys.CLEAR_SEARCH_FILTER:
      return { ...state, filter: initialState.filter };
    case BookingTypeKeys.SET_AVAILABLE_SLOTS:
      return {
        ...state,
        availableSlots: action.groupedSlots.slots,
        hasMoreHits: action.groupedSlots.hasMoreHits,
      };
    case BookingTypeKeys.CLEAR_AVAILABLE_SLOTS:
      return { ...state, availableSlots: {} };
    case BookingTypeKeys.SET_SELECTED_SLOT:
      return { ...state, selectedSlot: action.slot };
    case BookingTypeKeys.SET_BOOKING:
      return {
        ...state,
        booking: action.booking,
        extraFieldResults: action.booking.extraFields || [],
      };
    case BookingTypeKeys.SET_EXTRA_FIELD_RESULTS:
      return { ...state, extraFieldResults: action.extraFieldResults };
    case BookingTypeKeys.CLEAR_BOOKING:
      return { ...state, booking: undefined, page: BookingPage.SEARCH_SLOTS };
    case BookingTypeKeys.SET_COMPANY_SETTINGS:
      return { ...state, companySettings: action.companySettings };
    case BookingTypeKeys.SET_RESERVATION:
      return { ...state, reservation: action.reservation };
    case BookingTypeKeys.SET_CONTRACT_IDS:
      return { ...state, contractIds: action.contractIds };
    case BookingTypeKeys.SET_CARE_UNITS_FILTER:
      return { ...state, filter: { ...state.filter, careUnits: action.selectedCareUnits }}
    case BookingTypeKeys.SET_SERVICE_FILTER:
      return { ...state, filter: { ...state.filter, service: action.selectedService }}
    default:
      return state;
  }
};

export const useBooking = (): [BookingState, Dispatch<BookingAction>] => {
  const [state, dispatch] = useReducer(bookingReducer, initialState);
  return [state, dispatch];
};
