import { useContext } from "react";

import { ErrorStateContext } from "@Context/ErrorProvider";

const useErrorState = () => {
  const context = useContext(ErrorStateContext);
  if (context === undefined) {
    throw new Error("useErrorState must be used within a ErrorProvider");
  }
  return context;
};

export default useErrorState;
