import { FormField } from "grommet";
import styled from "styled-components";

const SearchFormField = styled(FormField)`
  & {
    min-height: 90px;
    border-color: ${({ theme }) => theme.global.colors.formFieldBorder};
    margin-bottom: 5px;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: auto;
    flex-basis: fit-content;
    flex-direction: column;
  }
  & > label {
    color: ${({ theme }) => theme.custom.menu.text};
    margin-left: 8px;
    margin-top: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
  }
  & input {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: ${({ theme }) => theme.custom.menu.text};
  }
`;

export default SearchFormField;
