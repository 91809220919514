import axios from "axios";

import { CareUnit, Service } from "./bookingTypes";
import { ApiPortalSettings, PortalSettings } from "./portalSettingsTypes";

export const fetchCareUnits = async (
    reservation?: string,
    contractIds?: string[]
): Promise<CareUnit[]> => {
  const url = reservation
      ? `/api/v1/careunit?reservation=${reservation}`
      : '/api/v1/careunit';


  const params = new URLSearchParams();

  if (contractIds) {
    params.append('contractIds', contractIds.join(","))
  }

  const results = await axios.get(url, {
    params,
    validateStatus: function (status) {
      return status === 200;
    },
  });
  return results.data;
};

export const fetchServices = async (
  clientId: string | undefined,
  reservation?: string,
  contractIds?: string[]
): Promise<Service[]> => {
  const url = reservation
      ? `/api/v1/service/${clientId}?reservation=${reservation}`
      : `/api/v1/service/${clientId}`;

  const params = new URLSearchParams();

  if (contractIds) {
    params.append('contractIds', contractIds.join(","))
  }

  const results = await axios.get(url, {
    params,
    validateStatus: function (status) {
      return status === 200;
    },
  });
  return results.data;
};

export const fetchPortalSettings = async (): Promise<PortalSettings> => {
  const results = await axios.get<ApiPortalSettings>("/api/v1/settings", {
    validateStatus: function (status) {
      return status === 200;
    },
  });
  const hours = results.data.hours.map((value, index) => ({
    label: value,
    value: index,
  }));
  return {
    earliestStartAtHours: hours,
    defaultDateRange: results.data.defaultDateRange,
  };
};

export const fetchContracts = async (
    clientId: string | undefined): Promise<string[]> => {
  const url = `/api/v1/contracts/${clientId}`;
  const results = await axios.get(url, {
    validateStatus: function (status) {
      return status === 200;
    },
  });
  return results.data;
};
