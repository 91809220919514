import { Box, Text } from "grommet";
import React from "react";
import { useIntl } from "react-intl";

import { popErrorAction } from "@Hooks/useError/actions";
import useErrorAction from "@Hooks/useError/useErrorAction";
import useErrorState from "@Hooks/useError/useErrorState";

import theme from "@Style/theme";

import Button from "../Common/Button";
import Modal from "../Common/Modal";
import messages from "./messages";

const ErrorModal: React.FC = () => {
  const { errors } = useErrorState();
  const errorDispatch = useErrorAction();
  const { formatMessage } = useIntl();

  const dismiss = () => {
    if (error.fixed !== true) {
      errorDispatch(popErrorAction());
    }
  };

  if (errors.length === 0) {
    return null;
  }
  const error = errors.pop()!;
  return (
    <Modal dismiss={dismiss} title={error.title}>
      <Box
        margin={{ bottom: "medium" }}
        pad={"small"}
        background={theme.custom.error.background}
        border={{ color: theme.custom.error.border }}
        round={"4px"}>
        <Text>{error.message}</Text>
      </Box>
      {error.fixed !== true && (
        <Box justify={"center"} direction={"row"}>
          <Button
            width={"140px"}
            fill={false}
            primary
            label={formatMessage(messages.button)}
            onClick={() => dismiss()}
          />
        </Box>
      )}
    </Modal>
  );
};

export default ErrorModal;
