export interface Error {
  title: string;
  message: string;
  fixed?: boolean;
}

export interface ErrorState {
  errors: Error[];
}

export interface PushErrorAction {
  type: ErrorTypeKeys.PUSH_ERROR;
  error: Error;
}

export interface PopErrorAction {
  type: ErrorTypeKeys.POP_ERROR;
}

export type ErrorAction = PushErrorAction | PopErrorAction;

export enum ErrorTypeKeys {
  PUSH_ERROR = "PUSH_ERROR",
  POP_ERROR = "POP_ERROR",
}
