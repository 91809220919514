import { Box, Heading, ResponsiveContext } from "grommet";
import React from "react";
import { useIntl } from "react-intl";

import theme from "@Style/theme";

import Button from "../Common/Button";
import Modal from "../Common/Modal";
import messages from "./messages";

interface Props {
  dismiss: () => void;
}

const CancelConfirmation: React.FC<Props> = ({ dismiss }) => {
  const { formatMessage } = useIntl();
  const size = React.useContext(ResponsiveContext);
  const small = "xsmall" === size || "medium" === size;
  const modalWidth = small ? "412px" : "652px";

  return (
    <Modal
      title={formatMessage(messages.title)}
      dismiss={dismiss}
      width={modalWidth}>
      <Box
        pad={"30px"}
        align={"center"}
        border={{ color: theme.global.colors.infoBoxBorder }}
        background={{ color: theme.global.colors.infoBoxBackground }}>
        <Heading level={3}>{formatMessage(messages.confirmation)}</Heading>
      </Box>
      <Box justify={"end"} direction={"row"} pad={{ top: "40px" }}>
        <Button
          width={"140px"}
          fill={false}
          primary
          label={formatMessage(messages.button)}
          onClick={dismiss}
        />
      </Box>
    </Modal>
  );
};

export default CancelConfirmation;
