import { Box, Heading, Layer, ResponsiveContext } from "grommet";
import React from "react";
import styled from "styled-components";

interface ModalProps {
  dismiss?: () => void;
  title: string;
  width?: string;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ children, dismiss, title, width }) => {
  const size = React.useContext(ResponsiveContext);
  const small = "xsmall" === size || "medium" === size;

  const padding = small
    ? { vertical: "5px", horizontal: "5px" }
    : { vertical: "32px", horizontal: "25px" };

  if (!width) {
    if (small) {
      width = "367px";
    } else {
      width = "480px";
    }
  }

  return (
    <ModalWrapper position={"center"} onEsc={dismiss} onClickOutside={dismiss}>
      <Box width={width} pad={padding}>
        <Heading margin={{ top: "0px" }} level={2}>
          {title}
        </Heading>
        {children}
      </Box>
    </ModalWrapper>
  );
};

const ModalWrapper = styled(Layer)`
  border-radius: 12px;
`;

export default Modal;
