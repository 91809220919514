export enum ErrorType {
  PATIENT_AND_BOOKING_PATIENT_MISMATCH = "PATIENT_AND_BOOKING_PATIENT_MISMATCH",
  REGISTERED_PATIENT_OR_CLIENT_NO_NEEDED = "REGISTERED_PATIENT_OR_CLIENT_NO_NEEDED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  BOOKING_NOT_FOUND = "BOOKING_NOT_FOUND",
  PATIENT_NOT_FOUND = "PATIENT_NOT_FOUND",
  NO_PATIENT_ON_BOOKING = "NO_PATIENT_ON_BOOKING",
  VIDEO_CONFIGURATION_ERROR = "VIDEO_CONFIGURATION_ERROR",
  BOOKING_OCCUPIED = "BOOKING_OCCUPIED",
  VIDEO_WOULD_RESULT_IN_COMPANY_BOOKING_WITH_NO_PATIENT = "VIDEO_WOULD_RESULT_IN_COMPANY_BOOKING_WITH_NO_PATIENT",
}
