import { Box, Heading, Image, ResponsiveContext, Spinner } from "grommet";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { Slot } from "@Api/bookingTypes";
import { setPage, setSelectedSlot } from "@Hooks/useBooking/actions";
import { BookingPage } from "@Hooks/useBooking/types";
import useBookingAction from "@Hooks/useBooking/useBookingAction";
import useBookingState from "@Hooks/useBooking/useBookingState";
import SlotComponent from "../Common/SlotComponent";
import EditBookingModal from "../EditBookingModal";
import messages from "./messages";
import { getSearchComplete } from "@Components/Search/Search";

interface Props { drawLoading: boolean }

const Result: React.FC<Props> = ({drawLoading}) => {
  const { formatMessage } = useIntl();
  const { booking, availableSlots, hasMoreHits, services, reservation } = useBookingState();
  const bookingDispatch = useBookingAction();
  const [showEditBookingModal, setShowEditBookingModal] = useState(false);

  const size = React.useContext(ResponsiveContext);
  const small = "xsmall" === size || "medium" === size;

  const selectSlot = (slot: Slot) => {
    bookingDispatch(setSelectedSlot(slot));

    if (booking) {
      setShowEditBookingModal(true);
    } else {
      bookingDispatch(setPage(BookingPage.PATIENT_DETAILS));
    }
  };

  const renderSlot = (slot: Slot, index: number) => {
    return (
      <SlotComponent
        small={small}
        key={"slot_" + index}
        video={slot.serviceType?.videoConsultation}
        serviceName={slot.serviceName}
        careUnit={slot.resource.careUnit}
        resource={slot.resource}
        startTime={slot.startTime}
        callText={slot.callText}
        button={{
          label: formatMessage(messages.select),
          primary: true,
          onSelect: () => {
            selectSlot(slot);
          },
        }}
      />
    );
  };

  const renderDate = (date: string) => {
    return (
      <Fragment key={"date_" + date}>
        <Box flex={false}>
          <Heading
            level="3"
            margin={{ top: "34px", bottom: "19px" }}
            style={{ textTransform: "capitalize" }}>
            {moment(date).format("ddd DD MMM")}
          </Heading>
        </Box>
        {availableSlots![date].map(renderSlot)}
      </Fragment>
    );
  };

  const renderNoSearch = () => {
    return (
      <Box pad={{ top: "60px" }}>
        <Heading level={3}>{reservation && services?.length < 1
          ? formatMessage(messages.noSearchResultPossible)
          : formatMessage(messages.noSearch)}</Heading>
      </Box>
    );
  };

  // If search is complete
  const renderNoResult = () => {
    return (
      <Box pad={{ top: "60px" }}>
        <Heading level={3}>{formatMessage(messages.noResult)}</Heading>
        <Heading level={4}>{formatMessage(messages.noResultTryAgain)}</Heading>
      </Box>
    );
  };

  const renderHasMoreHits = () => {
    const boxWidth = (small ? 360 : 650) + "px";
    return (
      <Box
        pad={{ top: "10px", bottom: "10px", left: "10px", right: "10px" }}
        width={{ max: boxWidth, min: boxWidth }}>
        <Heading level={3} alignSelf={"center"}>
          {formatMessage(messages.hasMorHits)}
        </Heading>
      </Box>
    );
  };

  const padding = small ? "none" : "medium";

  return (
    <Box pad={padding} fill overflow={"auto"}>
      {!booking && "xlarge" === size && (
        <Image
          src="api/v1/custom/logo.png"
          height={40}
          width={120}
          style={{ position: "absolute", top: "30px", right: "35px" }}
        />
      )}
      {!booking && ("large" === size || "medium" === size) && (
        <Image src="api/v1/custom/logo.png" height={40} width={120} alignSelf="end" />
      )}
      {availableSlots &&
        Object.keys(availableSlots).map((key) => renderDate(key))}
      {availableSlots && hasMoreHits && renderHasMoreHits()}
      {!availableSlots && renderNoSearch()}
      {availableSlots &&
        Object.keys(availableSlots).length === 0 && getSearchComplete() &&
        renderNoResult()}
      {showEditBookingModal && (
        <EditBookingModal dismiss={() => setShowEditBookingModal(false)} />
      )}

      {drawLoading &&
        <div style={{display: "flex", alignItems: "center"}}>
        <Spinner />
        <p style={{marginLeft: "10px"}}>{formatMessage(messages.searching)}</p>
        </div>
      }
    </Box>
  );
};

export default Result;
