import React, { Dispatch } from "react";

import { BookingAction, BookingState } from "@Hooks/useBooking/types";
import { useBooking } from "@Hooks/useBooking/useBooking";

export const BookingStateContext = React.createContext<
  BookingState | undefined
>(undefined);
export const BookingActionContext = React.createContext<
  Dispatch<BookingAction> | undefined
>(undefined);

interface BookingProviderProps {
  children?: React.ReactNode;
}

const BookingProvider: React.FC<BookingProviderProps> = ({ children }) => {
  const [state, actions] = useBooking();

  return (
    <BookingStateContext.Provider value={state}>
      <BookingActionContext.Provider value={actions}>
        {children}
      </BookingActionContext.Provider>
    </BookingStateContext.Provider>
  );
};

export default BookingProvider;
