import { Box, Heading, ResponsiveContext } from "grommet";
import React from "react";
import { useIntl } from "react-intl";

import useBookingState from "@Hooks/useBooking/useBookingState";

import Button from "../Common/Button";
import Modal from "../Common/Modal";
import SlotComponent from "../Common/SlotComponent";
import messages from "./messages";

interface Props {
  confirm: () => void;
  cancel: () => void;
}

const CancelBooking: React.FC<Props> = ({ confirm, cancel }) => {
  const { formatMessage } = useIntl();
  const { booking } = useBookingState();
  const size = React.useContext(ResponsiveContext);
  const small = "xsmall" === size || "medium" === size;
  const modalWidth = small ? "412px" : "652px";

  if (!booking) {
    return null;
  }

  return (
    <Modal
      title={formatMessage(messages.title)}
      dismiss={cancel}
      width={modalWidth}>
      <Box pad={{ vertical: "20px" }}>
        <SlotComponent
          small={small}
          video={booking.videoConsultation}
          serviceName={booking.mainService.name}
          careUnit={booking.resource.careUnit}
          resource={booking.resource}
          startTime={booking.startTime}
        />
      </Box>
      <Box pad={"30px"} align={"center"}>
        <Heading level={3}>{formatMessage(messages.confirmation)}</Heading>
      </Box>
      <Box justify={"center"} direction={"row"}>
        <Button
          margin={"25px"}
          width={"140px"}
          fill={false}
          secondary
          label={formatMessage(messages.cancelButton)}
          onClick={cancel}
        />
        <Button
          margin={"25px"}
          width={"140px"}
          fill={false}
          primary
          label={formatMessage(messages.confirmButton)}
          onClick={confirm}
        />
      </Box>
    </Modal>
  );
};

export default CancelBooking;
