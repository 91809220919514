import { defineMessages } from "react-intl";

export default defineMessages({
  header: "Tidbokning",
  type: "Typ av besök",
  servicePlaceholder: "Välj tjänst",
  typePlaceholder: "Alla",
  typeNoOptions: "Inga typer av besök att välja",
  careUnit: "Mottagning",
  careUnitPlaceholder: "Alla",
  careUnitNoOptions: "Inga mottagningar att välja",
  startDate: "Tidigast den",
  endDate: "Senast den",
  datePlaceholder: "Välj datum",
  earliestStartAt: "Tider från och med",
  earliestStartAtNoOptions: "Inga tider att välja",
  earliestStartAtPlaceholder: "Alla",
  button: "Sök",
  unkownError: "Sökningen kunde ej genomföras",
  unkownErrorMessage:
    "Sökningen kunde inte genomföras på grund av okänt fel. Utför sökningen igen eller ta kontakt med din mottagning.",
  callQueueLimitExceeded: "Tekniskt fel",
  callQueueLimitExceededMessage:
    "Applikationen har stannat. Ladda om sidan och försök igen.",
});
