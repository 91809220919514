import { defineMessages } from "react-intl";

export default defineMessages({
  header: "Tidbokning",
  confirmation: "Bokningsbekräftelse",
  bookingBoxHeader: "Du har bokat följande tid för",
  informationEmail: "Bokningsbekräftelse har skickats till e-postadress ",
  informationEmailVideo:
    "Bokningsbekräftelse med länk till videokonsultation har skickats till e-postadressen ",
  informationLink:
    "För att omboka eller boka av vid ett senare tillfälle kan du använda länken i din bokningsbekräftelse ",
  informationNoLink: "Kontakta din mottagning om du behöver ändra bokningen ",
  buttonEdit: "Omboka",
  buttonCancel: "Avboka",
  buttonNew: "Boka ny tid",
  errorCancelNotAllowedTitle: "Avbokning ej möjligt",
  errorCancelNotAllowedMessage:
    "Avbokning är inte tillåtet mindre än {hours} timmar innan bokningens start.",
  errorEditNotAllowedTitle: "Ombokning ej möjligt",
  errorEditNotAllowedMessage:
    "Ombokning är inte tillåtet mindre än {hours} timmar innan bokningens start.",
  errorCancelFailedTitle: "Avbokning misslyckades",
  errorCancelFailedMessage: "Avbokning misslyckades",
  bookingMissing: "Bokningen kan inte hittas, kontakta din mottagning",
});
