import { useContext } from "react";

import { BookingActionContext } from "@Context/BookingProvider";

const useBookingAction = () => {
  const context = useContext(BookingActionContext);
  if (context === undefined) {
    throw new Error("useBookingAction must be used within a BookingProvider");
  }
  return context;
};

export default useBookingAction;
