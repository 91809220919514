import axios from "axios";

import {
  LoginResponse, LoginSessionParameters
} from "./authTypes";
import { ApiClient, AuthType } from "./bookingTypes";

export const pnrLogin = async (
  pnr: string,
  clientId?: string,
  bookingId?: string
): Promise<LoginResponse> => {
  const result = await axios.post<LoginResponse>(
    "/api/v2/login/portal/timebook/civicregnum",
    {
      civicRegistrationNumber: pnr,
      clientId: clientId,
      bookingId: bookingId,
    },
    {
      validateStatus: function (status) {
        return status === 200;
      },
    }
  );
  return result.data;
};

export interface LoginSession {
  redirectUrl: string;
}

export const handleFederatedLogin = async (clientId?: string, bookingId?: string, reservation?: string): Promise<LoginSession> => {
  try {
    const response = await axios.get<LoginSession>(
      "/api/v2/login/portal/session",
      {
        params: {
          application: "TIMEBOOK",
          clientId: clientId,
          bookingId: bookingId,
          reservation: reservation
        }
      }
    );

    return response.data;
  } catch (error) {
    return await Promise.reject(error);
  }
};

export const finalizeLoginSession = async (params: LoginSessionParameters): Promise<LoginResponse> => {
  try {
    const response = await axios.post<LoginResponse>("/api/v2/login/portal/session", {
      application: "TIMEBOOK",
      sessionId: params.grandIdSession,
      clientId: params.clientId,
      bookingId: params.bookingId,
    });
    return response.data;
  } catch (error) {
    return await Promise.reject(error);
  }
}

export const fetchAuthType = async (): Promise<AuthType> => {
  const result = await axios.get("/api/v2/login/method/TIMEBOOK", {
    validateStatus: function (status) {
      return status === 200;
    },
  });
  return result.data;
};

export const fetchApiClient = async (): Promise<ApiClient> => {
  const result = await axios.get("/api/v1/me", {
    validateStatus: function (status) {
      return status === 200;
    },
  });
  return result.data;
};
