import React, { Dispatch } from "react";

import { ErrorAction, ErrorState } from "@Hooks/useError/types";
import { useError } from "@Hooks/useError/useError";

export const ErrorStateContext = React.createContext<ErrorState | undefined>(
  undefined
);
export const ErrorActionContext = React.createContext<
  Dispatch<ErrorAction> | undefined
>(undefined);

interface ErrorProviderProps {
  children?: React.ReactNode;
}

const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const [state, actions] = useError();

  return (
    <ErrorStateContext.Provider value={state}>
      <ErrorActionContext.Provider value={actions}>
        {children}
      </ErrorActionContext.Provider>
    </ErrorStateContext.Provider>
  );
};

export default ErrorProvider;
