import { defineMessages } from "react-intl";

export default defineMessages({
  title: "Omboka",
  changeTo: "Omboka till",
  information:
    "Genom att välja ny bokning avbokas den befintliga bokningen automatisk",
  cancelButton: "Avbryt",
  submitButton: "Omboka",
  editFailedTitle: "Någonting gick fel",
  editFailedMessage: "Ombokningen misslyckades",
  errorEditNotAllowedTitle: "Ombokning ej möjligt",
  errorEditNotAllowedMessage:
    "Ombokning är inte tillåtet mindre än {hours} timmar innan bokningens start.",
  bookingMissing: "Bokningen kan inte hittas, kontakta din mottagning",
  videoConfigurationError:
    "Det är ej möjligt att boka videokonsultation för vald personal. Vänligen välj annan personal.",
  bookingOccupied:
    "Tiden är redan tagen, försök på nytt genom att välja en annan tid",
});
